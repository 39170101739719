"use client";

import { config } from "@/config/config";
import { useNavigation } from "@/context/navigation";
import { Facebook, Instagram, Linkedin, Twitter } from "lucide-react";
import Image from "next/image";
import Link from "next/link";

const MobileMenu = () => {
  const { mobile, openMenuMobile } = useNavigation();

  return (
    <div className={`popup-mobile-menu ${mobile ? "active" : ""}`}>
      <div className="inner-wrapper">
        <div className="inner-top">
          <div className="content">
            <div className="logo">
              <Link href="/">
                <Image src="/novos/img/logo-colorida.png" width={98} height={50} alt="Aulé" loading="eager" />
              </Link>
            </div>
            <div className="rbt-btn-close">
              <button className="close-button rbt-round-btn" onClick={() => openMenuMobile(!mobile)}>
                <i className="feather-x"></i>
              </button>
            </div>
          </div>
          <p className="description">O reforço escolar simples e eficaz para o seu filho.</p>
        </div>
        <nav className="mainmenu-nav">
          <ul className="mainmenu">
            <li className="with-megamenu">
              <Link href="/temporario">Home</Link>
            </li>
            {config.enableModules.showNews && (
              <li className="with-megamenu">
                <Link href="/blog">Notícias</Link>
              </li>
            )}
            <li className="with-megamenu">
              <Link href={config.dash_shop}>Cursos</Link>
            </li>
            <li className="with-megamenu">
              <Link href="/quem-somos">Quem somos</Link>
            </li>
            <li className="with-megamenu">
              <Link href="/centrao-de-ajuda">Central de ajuda</Link>
            </li>
            <li className="with-megamenu">
              <Link href="/contato">Contato</Link>
            </li>
          </ul>
        </nav>
        <div className="mobile-menu-bottom">
          <div className="rbt-btn-wrapper mb--20">
            <Link href={config.dash_shop} className="rbt-btn btn-secondary radius-round w-100 justify-content-center text-center">
              <span>Começe agora</span>
            </Link>
          </div>
          <ul className="navbar-top-left rbt-information-list justify-content-start">
            {config.contactInfo.map((data, index) => (
              <li key={index} className="d-flex gap-3 align-items-center justify-content-center">
                <i className={data.icon}></i>
                {data.link ? <Link href={data.link}>{data.contact}</Link> : <span>{data.contact}</span>}
              </li>
            ))}
          </ul>
          <div className="social-share-wrapper">
            <ul className="social-icon social-default transparent-with-border justify-content-start mt--20">
              {config.social.facebook && (
                <li>
                  <a href={config.social.facebook} target="_blank">
                    <Facebook />
                  </a>
                </li>
              )}
              {config.social.twiter && (
                <li>
                  <a href={config.social.twiter} target="_blank">
                    <Twitter />
                  </a>
                </li>
              )}
              {config.social.instagram && (
                <li>
                  <a href={config.social.instagram} target="_blank">
                    <Instagram />
                  </a>
                </li>
              )}
              {config.social.linkdin && (
                <li>
                  <a href={config.social.linkdin} target="_blank">
                    <Linkedin />
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;

