import Instagram from "@/components/Instagram";
import SubscribeNewsletter from "@/components/SubscribeNewsletter";
import { config } from "@/config/config";
import { Facebook, Instagram as InstagramIco, Linkedin, Mail, MessageCircle, Twitter } from "lucide-react";
import Image from "next/image";
import Link from "next/link";
import React from "react";

const Footer: React.FC = () => {
  return (
    <>
      <a className="float-whatsapp" target="_blank" rel="noreferrer" href={config.social.whatsapp}>
        <Image src="/novos/img/teo-do-aule.png" width={300} height={300} alt="Ficou com alguma dúvida? Fale com nosso time" loading="eager" />
      </a>
      <footer className="row gap-4 mt-5">
        <SubscribeNewsletter />
        <Instagram />
        <div className="mt-5">
          <div className="container">
            <div className="row row--15 mt_dec--30">
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30">
                <div className="footer-widget">
                  <div className="logo">
                    <Link href="/">
                      <Image src="/novos/img/logo-colorida-player.png" width={200} height={117} alt="Aulé - O reforço que seu filho precisa para aprender" loading="eager" />
                    </Link>
                  </div>
                  <p className="description mt--20">Aulas rápidas, acompanhamento fácil e tudo o que seu filho precisa para progredir nos estudos!</p>
                  <div className="contact-btn mt--30">
                    <Link className="rbt-btn btn-secondary radius-round" href="/contato">
                      Entre em contato
                    </Link>
                  </div>
                </div>
              </div>
              <div className="offset-lg-1 col-lg-2 col-md-6 col-sm-6 col-12 mt--30">
                <div className="footer-widget">
                  <h5 className="ft-title">Cursos</h5>
                  <ul className="ft-link">
                    <li>
                      <Link href={`${config.dash_shop}?busca=#6-ano`}>6° ano</Link>
                    </li>
                    <li>
                      <Link href={`${config.dash_shop}?busca=#7-ano`}>7° ano</Link>
                    </li>
                    <li>
                      <Link href={`${config.dash_shop}?busca=#8-ano`}>8° ano</Link>
                    </li>
                    <li>
                      <Link href={`${config.dash_shop}?busca=#9-ano`}>9° ano</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-6 col-12 mt--30">
                <div className="footer-widget">
                  <h5 className="ft-title">Saiba mais</h5>
                  <ul className="ft-link">
                    <li>
                      <Link href={config.politica_privacidade}>Politica de privacidade</Link>
                    </li>
                    <li>
                      <Link href={config.termo_uso}>Termo de uso</Link>
                    </li>
                    <li>
                      <Link href="/quem-somos">Quem somos</Link>
                    </li>
                    {config.enableModules.showNews && (
                      <li>
                        <Link href="/blog">Blog</Link>
                      </li>
                    )}
                    <li>
                      <Link href="/contato">Atendimento</Link>
                    </li>
                    <li>
                      <Link href="/central-de-ajuda">Central de ajuda</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt--30">
                <div className="footer-widget">
                  <h5 className="ft-title">Contato</h5>
                  <ul className="ft-link">
                    <li>
                      <a href={config.social.whatsapp} target="_blank">
                        <MessageCircle size={17} /> Téo
                      </a>
                    </li>
                    <li>
                      <a href="/contato" target="_blank">
                        <Mail size={17} /> E-mail
                      </a>
                    </li>
                    <li>Uberlândia / MG</li>
                  </ul>
                  <ul className="social-icon social-default icon-naked justify-content-start mt--20">
                    {config.social.facebook && (
                      <li>
                        <a href={config.social.facebook} target="_blank">
                          <Facebook />
                        </a>
                      </li>
                    )}
                    {config.social.twiter && (
                      <li>
                        <a href={config.social.twiter} target="_blank">
                          <Twitter />
                        </a>
                      </li>
                    )}
                    {config.social.instagram && (
                      <li>
                        <a href={config.social.instagram} target="_blank">
                          <InstagramIco />
                        </a>
                      </li>
                    )}
                    {config.social.linkdin && (
                      <li>
                        <a href={config.social.linkdin} target="_blank">
                          <Linkedin />
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="mt-5 rbt-separator-mid">
        <div className="container">
          <hr className="rbt-separator m-0" />
        </div>
      </div>
      <div className="copyright-area copyright-style-1 ptb--20">
        <div className="container">
          <div className="row align-items-center">
            <div className="">
              <p className="rbt-link-hover text-center text-lg-start">Copyright © {new Date().getFullYear()} Todos os direitos reservados.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
